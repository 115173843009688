import { graphql, Link, useStaticQuery } from "gatsby";
import PropTypes from "prop-types";
import * as React from "react";
import NavMenu from "../../components/nav-menu.js";
import CampaignLogo from "../../images/campaign-theme/hero-logo.webp";
import * as styles from "./header.module.scss";
import Button from "../../components/button";

const Header = ({ siteTitle, home }) => {
const { site, backgroundYellow } = useStaticQuery(graphql`
    query HeaderQuery {
      site {
        siteMetadata {
          title
        }
      }
      backgroundYellow: file(
        relativePath: { eq: "campaign-theme/campaign-texture.webp" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  const { title } = site.siteMetadata;
  return (
    <header>
      <NavMenu />
      <div className={styles.container} style={{
            backgroundImage: `url( ${backgroundYellow.childImageSharp.fluid.src} )`,
          }}>
        <div
          className={styles.wrapper}

        >
          <div className={styles.campaignLogo}>
            <div
              className={styles.logoBox}
              data-sal={home && "slide-down"}
              data-sal-duration="1000"
              data-sal-delay="100"
              data-sal-easing="ease"
            >
              <Link to={"/"}>
                <img src={CampaignLogo} alt={siteTitle} />
              </Link>
            </div>
          </div>
          <div className={styles.sell}>
            <h1>Help us collect toys for local kids!</h1>
            <p>
                <span>Is your family looking for assistance?</span>{" "}
                <Link to={"/non-profits#non-profits"}>
                  Please&nbsp;click&nbsp;here
                </Link>
            </p>
            <Button href="#donate" scroll={true}>Donate now</Button>
          </div>

        </div>
      </div>
    </header>
  );
};

Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: ``,
};

export default Header;
