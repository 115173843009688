import React from "react";
import * as containerStyles from "./button.module.scss";
import scrollTo from 'gatsby-plugin-smoothscroll';

const Button = (props) => {
  // const post = props.data.markdownRemark
  return (
    <div className={containerStyles.button}>
      {props.scroll && <a onClick={() => scrollTo(props.href)}  target={props.target}>{props.children || "OK"}</a>}
      {!props.scroll && <a href={props.href}  target={props.target}>{props.children || "OK"}</a>}
    </div>
  );
};
export default Button;
