import { Link } from "gatsby";
import React, { Component } from "react";
import { FaBars, FaHome, FaTimes } from "react-icons/fa";
import Fade from "react-reveal/Fade";
import MenuItems from "../../static/data/nav-menu-items.json";
import * as styles from "./nav-menu.module.scss";

class NavMenu extends Component {
  constructor(props) {
    super(props);
    this.state = { showMenu: false };
    this.toggleMenu = this.toggleMenu.bind(this);
  }

  toggleMenu() {
    this.setState({ showMenu: !this.state.showMenu });
  }

  render() {
    const menuItems = MenuItems;

    const MenuStack = () => {
      return (
        <ul>
          <li>
            <a href="/">
              <FaHome />
            </a>
          </li>
          {menuItems.map((item, i) => (
            <li key={item.name}>
              <Link to={item.url}>{item.name}</Link>
            </li>
          ))}
        </ul>
      );
    };

    return (
      <div className={styles.container}>
        <div className={styles.mobile}>
          {!this.state.showMenu && (
            <div
              role="button"
              tabIndex={0}
              className={styles.mobileMenu}
              onClick={this.toggleMenu}
              onKeyDown={this.toggleMenu}
            >
              <FaBars />
            </div>
          )}
          {this.state.showMenu && (
            <div
              role="button"
              tabIndex={-1}
              className={styles.mobileMenu}
              onClick={this.toggleMenu}
              onKeyDown={this.toggleMenu}
            >
              <FaTimes />
            </div>
          )}
          <Fade top collapse when={this.state.showMenu}>
            <div>
              <div className={styles.menu}>
                <MenuStack />
              </div>
            </div>
          </Fade>
        </div>
        <section className={styles.desktop}>
          <div className={styles.menu}>
            <MenuStack />
          </div>
        </section>
      </div>
    );
  }
}
export default NavMenu;
